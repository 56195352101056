/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.wausImg {
    display: block;
    width: 100%;
    text-align: right;
}
.wausImg a{
    display: inline-block;
    width: 120px;
    right: 30px;
    bottom: 30px;
    position: fixed;
    z-index: 9999
}
.wausImg{
    max-width: 200px;
}
.cs-billing-softwareWrap{
    position: relative;
    padding: 20px;
}

.cs-billing-softwareWrap::before{
    content: '';
    display: block;
    position: absolute;
    background: rgb(0 0 0 / 63%);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;

}
.cs-billing-softwareWrap::after{
    content: 'Coming Soon';
    display: block;
    position: absolute;
    font-size: 50px;
    top: 50%;
    color: #fff;
    z-index: 99;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    text-align: center;
}
.cs-serviceBoxWrap a > div{
    border: none;
}
.cs-serviceBoxWrap   .textContainer {
    text-overflow: ellipsis;
    width: 250px;
    display: inline-block;
}   
.cs-logCA{
    font-size: 2em;
}
.cs-servicePrice {
    font-size: 1em;
    color: #000;
}
.cs-serviceBoxWrap a:hover .imageContainer {
    transform: scale(1.3);
}
.cs-wtashPbtn {
    text-align: center;
    display: block;
    margin: 0 !important;
    border: 1px solid #6416ff;
    border-radius: 21px;
    padding: 10px;
}